import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExportService {

  constructor() {
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = {Sheets: {'data': myworksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(myworkbook, {bookType: 'xlsx', type: 'array'});
    ExportService.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private static saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }

  public exportAsPDFFromData(data: any[], pdfFileName: string): void {
    const doc = new jsPDF('p', 'mm', 'a4'); // 'p' for portrait, 'a4' paper size
  
    // Define columns based on data keys
    const columns = Object.keys(data[0]).map(key => ({
      header: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize column headers
      dataKey: key
    }));
  
    // Generate the PDF table
    autoTable(doc, {
      head: [columns.map(col => col.header)],
      body: data.map(item => columns.map(col => item[col.dataKey])),
      startY: 20, // Position from the top
      theme: 'striped', // Optional: Use striped theme for table rows
      margin: { top: 10 },
    });
  
    // Save the PDF file
    doc.save(pdfFileName);
  }
}
