<div class="row">
    <div class="col-md-3 col-sm-12">
            <h3>
                Skills Data     
            </h3>      
    </div>
    <div class="col-md-5">
        <input class="" fullWidth [(ngModel)]="searchbyfields"  nbTooltip="Search Skills" nbTooltipStatus="primary" [disabled]="skills.length === 0" type="text"  fieldSize="large" nbInput placeholder="Search Skills">   
    </div>
    <div class="col-md-4 col-sm-8 mt-1" align="right">
        <a nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" [routerLink]="['/hr/skills/grid']" nbButton status="primary"> <nb-icon icon="grid-outline"></nb-icon>   </a>
        &nbsp;
        <a nbTooltip="Visualize data on dimensions grid 3x3" nbTooltipStatus="primary" [routerLink]="['/hr/skills/keypad']" nbButton status="warning"> <nb-icon icon="keypad-outline"></nb-icon>   </a>
        &nbsp;
        <a [routerLink]="['/hr/skills/table']" nbTooltip="Visualize data in table" nbTooltipStatus="primary" nbButton status="basic"> <nb-icon icon="list-outline"></nb-icon>   </a>
        &nbsp;
        <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success"><i class="fas fa-file-excel text-white"></i>  </button>
        &nbsp;

        <a [routerLink]="['/hr/skills/skill/create']" nbTooltip="Click here to create a new department" nbTooltipStatus="primary" nbButton status="info">
            <nb-icon icon="plus-outline"></nb-icon>
            Create new
        </a>
     
          <br>
    </div>



</div>
<br>
<div class="row" *ngIf="!skills || skills.length === 0">
    <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
</div>
<div [hidden]="skills.length === 0" class="row" *ngIf="(skills | filter : searchbyfields).length === 0">
    <nb-alert outline="danger" class="w-100 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
</div>

<div class="row mt-5">
    <div class="col-md-3">
       
        <div class="row ">
            <div class="col-lg-9">
                <nb-form-field>
                    <nb-icon nbPrefix class="mt-1" icon="funnel-outline"></nb-icon> 
                    <nb-select selected="0" (selectedChange)="filterByEmployee($event)"  fullWidth>
                        <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Employee</nb-option>
                        <nb-option value="All" (click)="getSkillsClick()"> &nbsp;&nbsp;&nbsp;All</nb-option>
                        <nb-option *ngFor="let employee of employees" value="{{employee.id}}"> 
                            &nbsp;&nbsp;&nbsp; <nb-user [name]="employee.employeeName" [picture]="employee.employeeProfileImage"></nb-user>
                        </nb-option>
                     
                    </nb-select>
                </nb-form-field>
           
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-9">
                <nb-form-field>
                    <nb-icon nbPrefix class="mt-1" icon="funnel-outline"></nb-icon>
                    <nb-select selected="0" fullWidth>
                        <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Experience</nb-option>
                        <nb-option value="All" (click)="getSkillsClick()"> &nbsp;&nbsp;&nbsp;All</nb-option>
                        <nb-option (click)="filterByExperience1($event)" value="Less Then 3"> &nbsp;&nbsp;&nbsp; Less Then 3 </nb-option>
                        <nb-option (click)="filterByExperience2($event)" value="Between 3 .. 5"> &nbsp;&nbsp;&nbsp; Between 3 .. 5 </nb-option>
                        <nb-option (click)="filterByExperience3($event)" value="Between 5 .. 8"> &nbsp;&nbsp;&nbsp; Between 5 .. 8 </nb-option>
                        <nb-option (click)="filterByExperience4($event)" value="More Then 8"> &nbsp;&nbsp;&nbsp; More Then 8 </nb-option>
                    </nb-select>
                </nb-form-field>
              
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <nb-form-field>
                    <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <nb-select  selected="0" fullWidth>
                        <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Score</nb-option>
                        <nb-option value="All" (click)="getSkillsClick()"> &nbsp;&nbsp;&nbsp;All</nb-option>
                        <nb-option (click)="filterByScore1($event)" value="Less Then 3"> &nbsp;&nbsp;&nbsp;Less Then 3 </nb-option>
                        <nb-option (click)="filterByScore2($event)" value="Between 3 .. 5">&nbsp;&nbsp;&nbsp;Between 3 .. 5 </nb-option>
                        <nb-option (click)="filterByScore3($event)" value="Between 5 .. 8">&nbsp;&nbsp;&nbsp;Between 5 .. 8 </nb-option>
                        <nb-option (click)="filterByScore4($event)" value="More Then 8">&nbsp;&nbsp;&nbsp;More Then 8 </nb-option>
                    </nb-select>
                </nb-form-field>
              
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-9">
                <nb-form-field>
                    <nb-icon nbPrefix class="mt-1" icon="flip-outline"></nb-icon>
                    <nb-select selected="0" fullWidth>
                        <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Skills</nb-option>
                        <nb-option value="All" (click)="getSkillsClick()"> &nbsp;&nbsp;&nbsp;All</nb-option>
                        <nb-option (click)="getSkillsAsc()" value="Ascending">&nbsp;&nbsp;&nbsp; Ascending </nb-option>
                        <nb-option (click)="getSkillsDesc()" value="Descending">&nbsp;&nbsp;&nbsp; Descending </nb-option>
                    </nb-select>
                </nb-form-field>
              
            </div>
        </div>
    </div>
    <div class="col-lg-9 col-md-9 col-sm-9">
        <div class="row">
            <table class="table">
                <thead class="bg-light">
                    <tr>
                        <th>Employee</th>
                        <th>Skill Name</th>
                        <th>Experience</th>
                        <th>Score</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tr *ngFor="let skill  of skills  | filter : searchbyfields | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: skills.length} ; let i =index">
                    <td>
                        <p>
                            <nb-user [name]="skill?.employee?.employeeName" [picture]="skill?.employee?.employeeProfileImage"></nb-user>
                        </p>
                    </td>
                    <td>
                        <p> {{skill.skillName}} </p>
                    </td>
                    <td>
                        <p> {{skill.experience}} </p>
                    </td>
                    <td>
                        <p> {{skill.score}} </p>
                    </td>
                    <td>
                        <button shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <nb-icon icon="more-horizontal-outline"></nb-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                           
                            <a style="text-decoration:none" nbTooltip="Click here to edit data" nbTooltipStatus="primary">
                                <button mat-menu-item [routerLink]="['/hr/skills/edit',skill.id]">
                                  <span ><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
                                </button>
                              </a>
                        
                              <a nbTooltip="Click here to archive data" nbTooltipStatus="primary">
                                <button mat-menu-item>
                                  <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>
                                </button>
                              </a>
                         
                             
                          </mat-menu>
                    </td>
                   
                </tr>
            </table>
            <nb-card class="card col-md-4" align="center" style="height: 35px; border-radius: 25px;">
                <pagination-controls class="pt-1 text-center" (pageChange)="current = $event"></pagination-controls>
            </nb-card> 
             
        </div>
    </div>
</div>

