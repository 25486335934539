import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenStorageService } from '../service/token/token.service';
import { throwError } from 'rxjs';
const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor   {

  constructor(private tokenService: TokenStorageService, private router: Router,
    ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.tokenService.getToken();

    if (token != null) {
      authReq = req.clone(
        {
          setHeaders: {Authorization: `Bearer ${token}`} },
        );
    }
    return next.handle(authReq).pipe(catchError(err => {
      // onError
      if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.router.navigate(['/auth/login']);

          }
      }
        return throwError(err);
      // return Observable.throw(err);
  },
  ));
  }
}

