
        <div class="row">
            <div class="col-md-3 col-sm-12">
                <h3>
                    Employees List
                                    </h3>
            </div>
            <div class="col-md-3 col-sm-8">
                    <input fieldSize="large" type="text" [disabled]="list.length === 0" nbInput fullWidth [(ngModel)]="searchbyfields"   placeholder="Search Employee">
            </div>
            <div class="col-md-4 mt-1">
              <a nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" [routerLink]="['/hr/employee/list_']" nbButton status="primary"> <nb-icon icon="grid-outline"></nb-icon>   </a>
              &nbsp;
              <a nbTooltip="Visualize data on dimensions grid 3x3" nbTooltipStatus="primary" [routerLink]="['/hr/employee/keypad/list_']" nbButton status="warning"> <nb-icon icon="keypad-outline"></nb-icon>   </a>
              &nbsp;
                <a [routerLink]="['/hr/employee/table']" nbTooltip="Visualize data in table" nbTooltipStatus="primary" nbButton status="basic"> <nb-icon icon="list-outline"></nb-icon>   </a>
                 &nbsp;

              <button nbTooltip="Export to Excel file" nbTooltipStatus="primary" nbButton status="success"  (click)="exportAsXLSX()"><i class="fas fa-file-excel text-white"></i>  </button>
              &nbsp;

            </div>

            <div  class="col-md-2">
                <a nbTooltip="Click here to create a new employee" status="info" nbTooltipStatus="primary" fullWidth  [routerLink]="['/hr/employee/create_']"  nbButton class=" mt-1">
                    <nb-icon icon="plus-outline"></nb-icon>
                     New Employee
                </a>
            <br>
        </div>


        <div class="row mt-3 mx-2">
          <div class="col-lg-2">
            <div class="row">

                    <div class="col-lg-12 mt-2">
                        <nb-form-field>
                          <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                          <nb-select [disabled]="list.length === 0" (selectedChange)="getEmpsByGender($event,'country')"  selected="0" class="w-100 mt-3">
                            <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Country</nb-option>
                            <nb-option value="All" (click)="getAllEmps()">&nbsp;&nbsp;&nbsp;All</nb-option>
                            <nb-option value="{{country.country_name}}" *ngFor="let country of countries">&nbsp;&nbsp;&nbsp;{{country.country_name}}</nb-option>

                          </nb-select>
                        </nb-form-field>

                    </div>
                     <br>
                     <div class="col-lg-12">

                      <nb-form-field>
                        <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                        <nb-select [disabled]="list.length === 0" (selectedChange)="getEmpsByRole($event, 'Role')" selected="0" class="w-100 mt-3">
                          <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Role</nb-option>
                          <nb-option value="All" (click)="getAllEmps()">&nbsp;&nbsp;&nbsp;All</nb-option>
                          <nb-option value="Commercial">&nbsp;&nbsp;&nbsp;Commercial</nb-option>
                          <nb-option value="Manager">&nbsp;&nbsp;&nbsp;Manager</nb-option>
                          <nb-option value="Employee">&nbsp;&nbsp;&nbsp;Employee</nb-option>
                          <nb-option value="Project Manager">&nbsp;&nbsp;&nbsp;Project Manager</nb-option>
                        </nb-select>
                      </nb-form-field>

                     </div>
                     <br>

                     <div class="col-lg-12">
                        <nb-form-field>
                          <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                          <nb-select [disabled]="list.length === 0" (selectedChange)="getEmpsByGender($event, 'Gender')" selected="0" class="w-100 mt-3">
                            <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Gender</nb-option>
                            <nb-option value="All" (click)="getAllEmps()">&nbsp;&nbsp;&nbsp;All</nb-option>
                            <nb-option value="Homme" >&nbsp;&nbsp;&nbsp;Homme</nb-option>
                            <nb-option value="Femme" >&nbsp;&nbsp;&nbsp;Femme</nb-option>

                          </nb-select>
                        </nb-form-field>

                     </div>
                     <br>

                     <div class="col-lg-12">
                      <nb-form-field>
                        <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                        <nb-select (selectedChange)="getEmpsByDepartment($event)" [disabled]="list.length === 0" selected="0" class="w-100 mt-3" >
                          <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Department</nb-option>
                          <nb-option value="All" (click)="getAllEmps()">&nbsp;&nbsp;&nbsp;All</nb-option>
                          <nb-option value="{{dep.id}}" *ngFor="let dep of listd" >&nbsp;&nbsp;&nbsp;{{dep.departmentName}}</nb-option>

                        </nb-select>
                      </nb-form-field>

                     </div>

                    <div class="col-lg-12 mt-3">
                      <nb-form-field>
                        <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
                        <nb-select [disabled]="list.length === 0" selected="0" class="w-100 mt-3">
                          <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Name</nb-option>
                          <nb-option value="All" (click)="getAllEmps()">&nbsp;&nbsp;&nbsp;All</nb-option>
                          <nb-option value="1" (click)="sortByNamesAsc()">&nbsp;&nbsp;&nbsp;Names (A-Z)</nb-option>
                          <nb-option value="2" (click)="sortByNamesDesc()">&nbsp;&nbsp;&nbsp;Names (Z-A)</nb-option>
                        </nb-select>
                      </nb-form-field>

                    </div>
                      <div class="col-lg-12 mt-3">
                         <label>Items Per Page &nbsp; : &nbsp; </label>
                         <input class="w-100" [disabled]="list.length === 0" type="number" nbInput [(ngModel)]="pageSize">
                      </div>

            </div>


    </div>
            <div  class="col-lg-10  mt-3"  #pdfTable  >
                <div class="row">
                    <div class="row w-100" *ngIf="!list || list.length === 0">
                        <nb-alert outline="danger" class="w-100 mt-3"> No Data Found.</nb-alert>
                    </div>
                    <div [hidden]="list.length === 0" class="row w-100" *ngIf="(list | filter : searchbyfields).length === 0">
                        <nb-alert outline="danger" fullWidth class="col-lg-12 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
                    </div>
                <nb-card

                class=" col-md-4 col-lg-4 col-sm-12" *ngFor="let emp of list | filter : searchbyfields | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: total}" >

                <nb-card-body>
                        <div class="row">

                            <div class="col-md-10 col-sm-10">

                                <nb-user [name]="emp.employeeName"  picture="{{emp.employeeProfileImage}}"></nb-user>

                                <div class="row mt-3">
                                    <span class="col-md-12 w-100">
                                        <nb-icon icon="at-outline"></nb-icon> &nbsp; {{emp.employeeEmail}}
                                    </span>
                                    <br>
                                    <span class="mt-2 col-md-12">
                                        <nb-icon icon="phone-outline"></nb-icon> &nbsp; {{emp.employeeCellPhone}}
                                    </span>
                                    <br>
                                    <span class="mt-2 col-md-12">
                                        <nb-icon icon="globe-outline"></nb-icon> &nbsp; {{emp.employeeCountry}}
                                    </span>
                                    <span class="mt-2 col-md-12">
                                        <nb-icon icon="home-outline"></nb-icon> &nbsp;
                                          <nb-tag-list>
                                            <nb-tag *ngIf="emp?.department?.departmentName !== null ; else data" status="basic" size="small" [text]="emp?.department?.departmentName"></nb-tag>
                                             <ng-template #data>&nbsp;
                                            <nb-tag  status="warning" size="small" [text]="NotAssignedYet"></nb-tag>
                                            </ng-template>
                                          </nb-tag-list>

                                    </span>
                                    <span class="mt-2 col-md-12">
                                        <nb-icon icon="person-outline"></nb-icon> &nbsp;  <nb-tag-list>
                                            <nb-tag *ngIf="emp?.roleEmployee !== null ;" status="primary" size="small" [text]="emp?.roleEmployee"></nb-tag>

                                          </nb-tag-list>
                                    </span>


                                </div>

                            </div>
                            <div class="col-md-2 col-sm-2 mt-2">
                                <button shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <nb-icon icon="more-horizontal-outline"></nb-icon>
                                  </button>
                                  <mat-menu #menu="matMenu">

                                    <a style="text-decoration: dotted;" [routerLink]="['/hr/employee/advanced/', emp.id]">
                                        <button mat-menu-item>
                                            <span><nb-icon class="text-info" icon="eye-outline"></nb-icon>&nbsp; Advanced</span>
                                        </button>
                                      </a>
                                    <a style="text-decoration: dotted;" [routerLink]="['/hr/employee/edit/', emp.id]">
                                      <button mat-menu-item>
                                          <span><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
                                      </button>
                                    </a>

                                    <a>
                                        <button mat-menu-item (click)="updateIsEmployeeArchived(emp, emp.id)">
                                            <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>

                                        </button>
                                    </a>

                                  </mat-menu>
                            </div>

                        </div>
                    </nb-card-body>

                   </nb-card>



                </div>

            </div>
            <nb-card class="card  ml-auto mr-auto" style="height: 35px; border-radius: 25px;">
              <pagination-controls class="pt-1" (pageChange)="current = $event"></pagination-controls>
            </nb-card>



        </div>
    </div>
