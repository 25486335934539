<div class="row">
    <div class="col-md-6 col-sm-12">
      <div class="row">
        <h3 class="more-vertical">
          Contracts List <nb-icon nbTooltip="Click here to reload your data" nbTooltipStatus="primary" class="ion-loop ng-star-inserted text-primary"></nb-icon> </h3>
        <input [(ngModel)]="search" class="w-50 mx-2"  nbTooltip="Fill this field to find department" nbTooltipStatus="primary" type="text"  fieldSize="large" nbInput placeholder="search by fields" >
      </div>

    </div>
    <div class="col-md-6 col-sm-8">
        <a nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" [routerLink]="['/hr/contracts/grid']" nbButton status="primary"> <nb-icon icon="grid-outline"></nb-icon>   </a>
        &nbsp;
        <a nbTooltip="Visualize data on dimensions grid 3x3" nbTooltipStatus="primary" [routerLink]="['/hr/contracts/keypad']" nbButton status="warning"> <nb-icon icon="keypad-outline"></nb-icon>   </a>
        &nbsp;
        <a [routerLink]="['/hr/contracts/table']" nbTooltip="Visualize data in table" nbTooltipStatus="primary" nbButton status="basic"> <nb-icon icon="list-outline"></nb-icon>   </a>
        &nbsp;
        <button (click)="exportAsXLSX()" nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success" ><i class="fas fa-file-excel text-white"></i>  </button>
        &nbsp;


        <br>
  </div>
<div class="page-content container note-has-grid mt-4">

    <div class="row">
        <div class="col-md-3">
            <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                <nb-select  (selectedChange)="filtreByContratType($event,'contractType')" selected="0" class="w-75 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Contract</nb-option>
                  <nb-option value="All" (click)="getContracts()">&nbsp;&nbsp;&nbsp;&nbsp; Default</nb-option>
                  <nb-option value="CDD" >&nbsp;&nbsp;&nbsp;&nbsp;CDD</nb-option>
                  <nb-option value="CDI" >&nbsp;&nbsp;&nbsp;&nbsp;CDI</nb-option>

                </nb-select>
              </nb-form-field>
              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                <nb-select  (selectedChange)="filtreByStatus($event,'status')" selected="0" class="w-75 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Status</nb-option>
                  <nb-option value="All" (click)="getContracts()">&nbsp;&nbsp;&nbsp;&nbsp; Default</nb-option>
                  <nb-option value="In Trial Period" >&nbsp;&nbsp;&nbsp;&nbsp;In Trial Period</nb-option>
                  <nb-option value="Validate Trial Period" >&nbsp;&nbsp;&nbsp;&nbsp;Validate Trial Period</nb-option>
                  <nb-option value="Renewed Trial Period" >&nbsp;&nbsp;&nbsp;&nbsp;Renewed Trial Period</nb-option>
                  <nb-option value="Failure Trial Period" >&nbsp;&nbsp;&nbsp;&nbsp;Failure Trial Period</nb-option>


                </nb-select>
              </nb-form-field>
              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                <nb-select  (selectedChange)="filtreByHoursPlan($event,'hourlyDistribution')" selected="0" class="w-75 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Hourly Distribution</nb-option>
                  <nb-option value="All" (click)="getContracts()">&nbsp;&nbsp;&nbsp;&nbsp; Default</nb-option>
                  <nb-option value="40" >&nbsp;&nbsp;&nbsp;&nbsp; 40 Hours</nb-option>
                  <nb-option value="48" >&nbsp;&nbsp;&nbsp;&nbsp; 48 Hours</nb-option>

                </nb-select>
              </nb-form-field>
              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
                <nb-select  selected="0" class="w-75 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Hiring Date</nb-option>
                  <nb-option (click)="getContractsAscHiringDate()" value="" >Ascending</nb-option>
                  <nb-option (click)="getContractsDescHiringDate()" value="" >Descending</nb-option>

                </nb-select>
              </nb-form-field>
              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
                <nb-select  selected="0" class="w-75 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; End Date</nb-option>
                  <nb-option (click)="getContractsAscEndDate()" value="" >Ascending</nb-option>
                  <nb-option (click)="getContractsDescEndDate()" value="" >Descending</nb-option>

                </nb-select>
              </nb-form-field>
              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
                <nb-select  selected="0" class="w-75 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Official Signature</nb-option>
                  <nb-option (click)="getContractsAscOfficialSignature()" value="" >Ascending</nb-option>
                  <nb-option (click)="getContractsDescOfficialSignature()" value="" >Descending</nb-option>

                </nb-select>
              </nb-form-field>
              <nb-form-field>
                <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
                <nb-select  selected="0" class="w-75 mt-3">
                  <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Gross Annual Salary</nb-option>
                  <nb-option (click)="getContractsAscGrossAnnualSalary()" value="" >Ascending</nb-option>
                  <nb-option (click)="getContractsDescGrossAnnualSalary()" value="" >Descending</nb-option>

                </nb-select>
              </nb-form-field>
              <div class="mt-3">
                <label>Item Per Page</label>
                <input class="w-75" [disabled]="contracts.length === 0" type="number" nbInput [(ngModel)]="pageSize" min="9">

              </div>

        </div>
        <div class="col-md-9">
            <div class="tab-content">
                <div id="note-full-container" class="note-has-grid row">
                  <div class="row w-100" *ngIf="!contracts || contracts.length === 0">
                    <nb-alert outline="danger" class="w-100 mt-3"> No Data Found.</nb-alert>
                </div>
                <div [hidden]="contracts.length === 0" class="row w-100" *ngIf="(contracts | filter : search).length === 0">
                    <nb-alert outline="danger" fullWidth class="col-lg-12 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
                </div>
                    <div *ngFor="let contract of contracts | filter : search | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: total}" class="col-md-4 single-note-item all-category">
                        <nb-card class=" card-body">
                            <span class="side-stick"></span>
                            <div class="row">
                                <div class="col-md-9">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <nb-user [name]="contract?.employee?.employeeName" size="medium" onlyPicture   picture="{{contract?.employee?.employeeProfileImage}}"></nb-user>
                                        </div>
                                        <div class="col-md-9">
                                            <p class=" mt-2 mb-0">
                                                {{contract?.employee?.employeeName}} </p>
                                        </div>
                                    </div>

                                    <p class="small w-100">
                                        <nb-icon icon="calendar-outline"></nb-icon>
                                        {{contract.hiringDate | date : 'YYYY MMM dd'}}
                                         <nb-icon class="mt-4 text-primary" icon="arrow-right-outline"></nb-icon>
                                         {{contract.endDate | date : 'YYYY MMM dd'}}

                                    </p>
                                </div>
                                <div class="col-md-3">
                                    <button class="print-none" shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <nb-icon icon="more-horizontal-outline"></nb-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">

                                        <a [routerLink]="['/hr/contracts/contract/details',contract.id]" style="text-decoration: dotted;">
                                            <button mat-menu-item>
                                                <span><nb-icon class="text-info" icon="eye-outline"></nb-icon>&nbsp; Advanced</span>
                                            </button>
                                          </a>
                                        <a [routerLink]="['/hr/contracts/contract/edit-contract',contract.id]" style="text-decoration: dotted;">
                                          <button mat-menu-item>
                                              <span><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
                                          </button>
                                        </a>

                                      <a [routerLink]="['/hr/pay/paysheetform/',contract.id]" style="text-decoration: dotted;">
                                        <button mat-menu-item>
                                          <span><nb-icon class="text-danger" icon="file-text-outline"></nb-icon>&nbsp; Pay Form</span>
                                        </button>
                                      </a>

                                        <a>
                                            <button mat-menu-item>
                                                <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>

                                            </button>
                                        </a>

                                      </mat-menu>
                                </div>
                            </div>

                            <div class="note-content">
                                <p class="note-inner-content" >
                                    <nb-icon class="mt-2" icon="file-text-outline"></nb-icon>&nbsp;
                                    <nb-tag-list>
                                        <nb-tag status="primary" size="small" [text]="contract.contractType"></nb-tag>
                                    </nb-tag-list>
                                </p>
                                <p class="note-inner-content" >
                                    <nb-icon class="mt-2" icon="flag-outline"></nb-icon>&nbsp;
                                    <nb-tag-list>
                                        <nb-tag status="basic" size="small" [text]="contract.status"></nb-tag>
                                    </nb-tag-list>
                                </p>
                                <p class="" >
                                    <nb-icon class="mt-2" icon="edit-outline"></nb-icon>&nbsp;
                                   {{contract.officialSignature | date : 'YYYY MMM dd'}}
                                </p>
                                <p class="" >
                                    <nb-icon class="mt-2" icon="person-outline"></nb-icon>&nbsp;
                                    {{contract?.employee?.roleEmployee}}
                                 </p>
                                <p class="" >
                                   &nbsp; <i class="fa fa-solid fa-dollar-sign"></i> &nbsp;
                                   {{contract.grossAnnualSalary}}
                                </p>
                            </div>

                        </nb-card>
                    </div>


                </div>



            </div>



        </div>
        <div class=" ml-auto mr-auto">
          <nb-card class="bg-light w-100" style="height: 35px;margin-left: 0; border-radius: 25px;">
            <pagination-controls class="pt-1 text-center" (pageChange)="current = $event"></pagination-controls>
          </nb-card>
        </div>
    </div>




</div>
</div>
