
<div class="container">

    <div class="row">
        <div class="col-md-3">
          <h3>Jobs</h3>
        </div>
        <div class="col-md-6">
          <input [(ngModel)]="searchItem" type="text" fieldSize="large" fullWidth placeholder="search item ..." nbInput />
        </div>
        <div class="col-md-3 mt-1" align="right">
            <a [routerLink]="['/hr/recruitment/jobs/grid']" status="primary" nbButton>
              <nb-icon icon="grid-outline"></nb-icon>
            </a>
            &nbsp;
            <a [routerLink]="['/hr/recruitment/jobs/keypad']" status="warning" nbButton>
             <nb-icon icon="keypad-outline"></nb-icon>
           </a>
           &nbsp;
           <a [routerLink]="['/hr/recruitment/jobs/table']" status="basic" nbButton>
             <nb-icon icon="list-outline"></nb-icon>
           </a>
           &nbsp;
           <a [routerLink]="['/hr/recruitment/jobs/addJob']"  status="primary" nbButton>
             <nb-icon icon="plus-outline"></nb-icon> Add
           </a>
           
         </div>
       
    
      </div>

      <div class="row mt-3">
          <div class="col-md-3">
              <div class="row">
                  <div class="col-lg-11">
                    <nb-form-field>
                        <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                        <nb-select (selectedChange)="filterByTitle($event)"  selected="0" class="w-75 mt-3">
                          <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Title</nb-option>
                          <nb-option value="All" (click)="callAll()">&nbsp;&nbsp;&nbsp;All</nb-option>
                          <nb-option value="{{job.jobTitle}}" *ngFor="let job of jobs">&nbsp;&nbsp;&nbsp;{{job.jobTitle}}</nb-option>

                        </nb-select>
                      </nb-form-field>
                  </div>
                  <div class="col-lg-11">
                    <nb-form-field>
                        <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                        <nb-select (selectedChange)="filterByCategory($event)"  selected="0" class="w-75 mt-3">
                          <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Category</nb-option>
                          <nb-option value="All" (click)="callAll()">&nbsp;&nbsp;&nbsp;All</nb-option>
                          <nb-option  *ngFor="let category of categories" value="{{category.id}}">&nbsp;&nbsp;&nbsp;{{category.categoryName}}</nb-option>

                        </nb-select>
                      </nb-form-field>
                  </div>
                  <div class="col-lg-11">
                    <nb-form-field>
                        <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                        <nb-select (selectedChange)="filterByOffice($event)"  selected="0" class="w-75 mt-3">
                          <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Office</nb-option>
                          <nb-option value="All" (click)="callAll()">&nbsp;&nbsp;&nbsp;All</nb-option>
                          <nb-option value="{{job.jobOffice}}" *ngFor="let job of jobs">&nbsp;&nbsp;&nbsp;{{job.jobOffice}}</nb-option>

                        </nb-select>
                      </nb-form-field>
                  </div>
                  <div class="col-lg-11">
                    <nb-form-field>
                        <nb-icon nbPrefix class="mt-3" icon="funnel-outline"></nb-icon>
                        <nb-select (selectedChange)="filterByProfiles($event)"  selected="0" class="w-75 mt-3">
                          <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Profiles Need</nb-option>
                          <nb-option value="All" (click)="callAll()">&nbsp;&nbsp;&nbsp;All</nb-option>
                          <nb-option value="{{job.profile_needed}}" *ngFor="let job of jobs">&nbsp;&nbsp;&nbsp;{{job.profile_needed}}</nb-option>

                        </nb-select>
                      </nb-form-field>
                  </div>
                  <div class="col-lg-11">
                    <nb-form-field>
                        <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
                        <nb-select   selected="0" class="w-75 mt-3">
                          <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Title</nb-option>
                          <nb-option value="All" (click)="callAll()">&nbsp;&nbsp;&nbsp; All</nb-option>
                          <nb-option value="Ascending" (click)="callAscJobs()">&nbsp;&nbsp;&nbsp; Ascending</nb-option>
                          <nb-option value="Descending" (click)="callDescJobs()">&nbsp;&nbsp;&nbsp; Descending</nb-option>

                        </nb-select>
                      </nb-form-field>
                  </div>
                  <div class="col-lg-11">
                    <nb-form-field>
                        <nb-icon nbPrefix class="mt-3" icon="flip-outline"></nb-icon>
                        <nb-select   selected="0" class="w-75 mt-3">
                          <nb-option value="0" disabled>&nbsp;&nbsp;&nbsp; Number Of Positions</nb-option>
                          <nb-option value="All" (click)="callAll()">&nbsp;&nbsp;&nbsp; All</nb-option>
                          <nb-option value="Ascending" (click)="callAscJobs_Pos()">&nbsp;&nbsp;&nbsp; Ascending</nb-option>
                          <nb-option value="Descending" (click)="callDescJobs_Pos()">&nbsp;&nbsp;&nbsp; Descending</nb-option>

                        </nb-select>
                      </nb-form-field>
                  </div>
              </div>

          </div>
          <div class="col-md-9">
              <div class="row">
                <div class="row w-100" *ngIf="!jobs || jobs.length === 0">
                    <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
                </div>
                <div [hidden]="jobs.length === 0" class="row w-100" *ngIf="(jobs | filter : searchItem).length === 0">
                    <nb-alert outline="danger" fullWidth class="col-lg-12 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
                </div>
                <nb-card class="col-md-5 mx-3" *ngFor="let job of jobs |filter : searchItem | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: total}">
                    <div class="row mt-2 mx-2">
                        <div class="col-md-10">
                            <nb-user [name]="job.jobTitle"></nb-user>
                            <p class="mt-2 mx-5">
                                <nb-icon status="primary" nbTooltip="Job Category" icon="layers-outline"></nb-icon> {{job?.jobsCategory?.categoryName}}
                            </p>
                            <p class="mt-2 mx-5">
                                <nb-icon status="primary" nbTooltip="Job Office" icon="home-outline"></nb-icon> {{job.jobOffice}}
                            </p>
                            <p class="mt-2 mx-5">
                                <nb-icon status="primary" nbTooltip="Number Of Positions" icon="people-outline"></nb-icon> {{job.nb_people_positions}}
                            </p>
                            <p class="mt-2 mx-5">
                                <nb-icon status="primary" nbTooltip="Profiles Need" icon="person-done-outline"></nb-icon> {{job.profile_needed}}
                            </p>
                            <p class="mt-2 mx-5">
                                <nb-icon status="primary" nbTooltip="Place" icon="navigation-2-outline"></nb-icon> {{job.place}}
                            </p>
                          
                                
<!--                                 <app-read-more class="mt-2 mx-5" [text]="job.jobDescription"  maxLength="25"></app-read-more>
 -->
                        </div>
                        <div class="col-md-2 mt-1">
                            <button class="print-none" shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <nb-icon icon="more-horizontal-outline"></nb-icon>
                              </button>
                              <mat-menu #menu="matMenu">
                   
                                <a style="text-decoration: dotted;">
                                    <button mat-menu-item>
                                        <span><nb-icon class="text-info" icon="eye-outline"></nb-icon>&nbsp; Advanced</span>
                                    </button>
                                  </a>
                                <a style="text-decoration: dotted;" >
                                  <button mat-menu-item>
                                      <span><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
                                  </button>
                                </a>
                              
                                <a>
                                    <button mat-menu-item>
                                        <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>
        
                                    </button>
                                </a>
                            
                              </mat-menu>
                        </div>
                    </div>
                </nb-card>
             
              </div>
              <nb-card class="card col-md-4" align="center" style="height: 35px; border-radius: 25px;">
                <pagination-controls class="pt-1 text-center" (pageChange)="current = $event"></pagination-controls>
            </nb-card>  
           
          </div>
      </div>
</div>
