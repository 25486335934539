<div class="container">
    <h3>Recruitment Workflow</h3>
<nb-card size="medium">
    <nb-card-body>
      <nb-stepper orientation="horizontal">
        <nb-step label="Job Offer">
          <ngx-category-jobs></ngx-category-jobs>
        </nb-step>
        <nb-step label="Applications">
          <h4>Step content #2</h4>
          <p class="lorem">
            Proin varius accumsan semper. Praesent consequat tincidunt sagittis. Curabitur egestas sem a ipsum bibendum,
            sit amet fringilla orci efficitur. Nam bibendum lectus ut viverra tristique. Fusce eu pulvinar magna, quis
            viverra ex. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent metus turpis, commodo vel
            placerat quis, lobortis in ligula.
          </p>
          <p class="lorem">
            Curabitur luctus mattis risus nec condimentum. Donec at dui turpis. Sed vehicula fringilla rutrum. Nullam
            sed ornare magna. Mauris vitae laoreet diam. Mauris fermentum ligula at lacinia semper. Nulla placerat dui
            eu sapien pellentesque, eu placerat leo luctus. Cras pharetra blandit fermentum.
          </p>
          <p class="lorem">
            Proin varius accumsan semper. Praesent consequat tincidunt sagittis. Curabitur egestas sem a ipsum bibendum,
            sit amet fringilla orci efficitur. Nam bibendum lectus ut viverra tristique. Fusce eu pulvinar magna, quis
            viverra ex. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent metus turpis, commodo vel
            placerat quis, lobortis in ligula.
          </p>
          <p class="lorem">
            Curabitur luctus mattis risus nec condimentum. Donec at dui turpis. Sed vehicula fringilla rutrum. Nullam
            sed ornare magna. Mauris vitae laoreet diam. Mauris fermentum ligula at lacinia semper. Nulla placerat dui
            eu sapien pellentesque, eu placerat leo luctus. Cras pharetra blandit fermentum.
          </p>
          <div class="row">
            <div class="col-md-2">
              <button nbButton disabled nbStepperNext>prev</button>
            </div>
            <div class="col-md-8">

            </div>
            <div class="col-md-2">
              <button nbButton nbStepperNext>next</button>
            </div>
        </div>
        </nb-step>
        <nb-step label="Interviews">
          <h4>Step content #3</h4>
          <p class="lorem">
            Proin varius accumsan semper. Praesent consequat tincidunt sagittis. Curabitur egestas sem a ipsum bibendum,
            sit amet fringilla orci efficitur. Nam bibendum lectus ut viverra tristique. Fusce eu pulvinar magna, quis
            viverra ex. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent metus turpis, commodo vel
            placerat quis, lobortis in ligula.
          </p>
          <p class="lorem">
            Curabitur luctus mattis risus nec condimentum. Donec at dui turpis. Sed vehicula fringilla rutrum. Nullam
            sed ornare magna. Mauris vitae laoreet diam. Mauris fermentum ligula at lacinia semper. Nulla placerat dui
            eu sapien pellentesque, eu placerat leo luctus. Cras pharetra blandit fermentum.
          </p>
          <p class="lorem">
            Proin varius accumsan semper. Praesent consequat tincidunt sagittis. Curabitur egestas sem a ipsum bibendum,
            sit amet fringilla orci efficitur. Nam bibendum lectus ut viverra tristique. Fusce eu pulvinar magna, quis
            viverra ex. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent metus turpis, commodo vel
            placerat quis, lobortis in ligula.
          </p>
          <p class="lorem">
            Curabitur luctus mattis risus nec condimentum. Donec at dui turpis. Sed vehicula fringilla rutrum. Nullam
            sed ornare magna. Mauris vitae laoreet diam. Mauris fermentum ligula at lacinia semper. Nulla placerat dui
            eu sapien pellentesque, eu placerat leo luctus. Cras pharetra blandit fermentum.
          </p>
          <div class="row">
            <div class="col-md-2">
              <button nbButton disabled nbStepperNext>prev</button>
            </div>
            <div class="col-md-8">

            </div>
            <div class="col-md-2">
              <button nbButton nbStepperNext>next</button>
            </div>
        </div>
        </nb-step>
      </nb-stepper>
    </nb-card-body>
  </nb-card>
</div>
