import { Component, OnInit } from '@angular/core';

import {Notifications} from '../../models/Notifications';

import {Router} from '@angular/router';
import {NotificationService} from '../../services/notificationService/notification.service';
import {NbDialogRef} from '@nebular/theme';




@Component({

  selector: 'ngx-notificaton',

  templateUrl: './notificaton.component.html',

  styleUrls: ['./notificaton.component.scss'],

})

export class NotificatonComponent implements OnInit {

  // notifications: Notifications = new Notifications();

  notifications: Notifications[] = []; // Use the correct type here

  private numero: any;

  badgeText = '';


  constructor(private router: Router, private notificationService: NotificationService,
              private dialogRef: NbDialogRef<NotificatonComponent>) { }




  ngOnInit(): void {
    this.notificationService.notifications$.subscribe(notifications => {
      this.notifications = notifications;
      this.updateNotificationBadge(); // Update badge when notifications change
    });
    this.getAllNotifications();
  }

  getAllNotifications() {

    this.notificationService.getAllNotif().subscribe(

      (data: Notifications[]) => {

        this.notifications = data;

        console.log('data' , data);

      },

      (error) => {

        console.error(error);

      },

    );

  }


  onDeleteAllConfirm() {

    this.notificationService.deleteAll().subscribe(

      () => {
        this.router.navigate(['/hr/absences/global_calendar']).then(() => {

          console.log('Navigated to Calendar after deletion.');

        });

        console.log('All notifications deleted successfully.');

      },

      (error) => {

        console.error('Error deleting notifications:', error);

      },

    );
    this.dialogRef.close(); // Close the dialog

    this.router.navigate(['/hr/absences/global_calendar']).then(() => {
      console.log('Navigated to Calendar after deletion.');
    });


  }
  updateNotificationBadge() {
    this.badgeText = this.notifications.length.toString();
  }

}

