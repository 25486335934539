<div class="row">
  <div class="col-md-6 col-sm-12">
    <div class="row">
      <h2 class="more-vertical">
        Department List <nb-icon nbTooltip="Click here to reload your data" nbTooltipStatus="primary" class="ion-loop ng-star-inserted text-primary"></nb-icon> </h2>
      <input class="w-50 mx-2"  nbTooltip="Fill this field to find department" nbTooltipStatus="primary" [disabled]="list.length === 0" type="text"  fieldSize="large" nbInput placeholder="search by fields" [(ngModel)]="searchbyfields">
    </div>

  </div>
  <div class="col-md-6 col-sm-8">
    <a nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" [routerLink]="['/hr/department/grid']" nbButton status="primary"> <nb-icon icon="grid-outline"></nb-icon>   </a>
    &nbsp;
    <a nbTooltip="Visualize data on dimensions grid 3x3" nbTooltipStatus="primary" [routerLink]="['/hr/department/list']" nbButton status="warning"> <nb-icon icon="keypad-outline"></nb-icon>   </a>
    &nbsp;
    <a [routerLink]="['/hr/department/table']" nbTooltip="Visualize data in table" nbTooltipStatus="primary" nbButton status="basic"> <nb-icon icon="list-outline"></nb-icon>   </a>
    &nbsp;
    <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success"  (click)="exportAsXLSX()"><i class="fas fa-file-excel text-white"></i>  </button>
    &nbsp;

    <a [routerLink]="['/hr/department/create']" nbTooltip="Click here to create a new department" nbTooltipStatus="primary" nbButton status="info">
          <nb-icon icon="plus-outline"></nb-icon>
          Create new
    </a>
       &nbsp;
          <a nbTooltip="Click here to create a new sub department" nbTooltipStatus="primary" [routerLink]="['/hr/subdepartment/createSub']" status="basic"  nbButton class="mt-1 mx-1">
              <nb-icon icon="plus-outline"></nb-icon>
              New Sub
          </a>
      <br>
</div>
  <div class="col-md-2 col-sm-4">



    <br>
  </div>

</div>
<div class="col-md-2 col-sm-4">



</div>
<br>
<div class="row" *ngIf="!list || list.length === 0">
  <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
</div>
<div [hidden]="list.length === 0" class="row" *ngIf="(list | filter : searchbyfields).length === 0">
  <nb-alert outline="danger" class="w-100 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
</div>

<div class="row mt-5">
  <nb-card class="col-lg-6 col-md-8 col-sm-12" *ngFor="let department  of list  | filter : searchbyfields | paginate : {itemsPerPage:4, currentPage: current,totalItems: total} ; let i =index">

    <nb-card-body>
      <div class="row">
        <div class="col-md-10">
          <nb-user [name]="department.departmentName"></nb-user>
          <br>
          <span *ngIf="department?.employee?.employeeName !== null">Responsable : {{department?.employee?.employeeName}}</span>
          <br><br>
        </div>
        <div class="col-md-2">
          <button shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <nb-icon icon="more-horizontal-outline"></nb-icon>
          </button>
          <mat-menu #menu="matMenu">
            <a style="text-decoration:none" nbTooltip="Click here to edit data" nbTooltipStatus="primary" [routerLink]="['/hr/department/update/', department.id]">
              <button mat-menu-item>
                <span ><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
              </button>
            </a>
            <a nbTooltip="Click here to archive data" nbTooltipStatus="primary" (click)="updateIsDepartmentArchived(department, department.id)">
              <button mat-menu-item>
                <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>
              </button>
            </a>

          </mat-menu>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let sub of subList">
          <div *ngIf="sub?.department?.departmentName === department.departmentName">
            <button nbButton shape="round" size="small" status="dark">
              {{sub?.subDepartmentName}} <nb-icon (click)="open2(dialog)" nbButton shape="round" status="danger" icon="close-outline"></nb-icon>
            </button>
            <ng-template #dialog let-data let-ref="dialogRef">
              <nb-card>
                <nb-card-header>Confirm Delete Operation</nb-card-header>
                <nb-card-body>Are you sure you want to delete a sub department having name {{sub?.subDepartmentName}}</nb-card-body>
                <nb-card-footer>
                  <div class="row">
                    <button class="col-md-2" nbButton (click)="ref.close()">Close</button>
                    <div class="col-md-8"></div>
                    <a class="col-md-2" status="danger"   nbButton (click)="deleteSubDepartment(sub)">Delete</a>
                  </div>
                </nb-card-footer>
              </nb-card>
            </ng-template>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
  <nb-card class="card ml-auto mr-auto" style="height: 35px; border-radius: 25px;">
    <pagination-controls class="pt-1" (pageChange)="current = $event"></pagination-controls>
  </nb-card>
</div>
