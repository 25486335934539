export class Application {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    gender: string;
    phoneNumber: string;
    status: string;
    curriculumVitae: string;
    refJob: string;
}
