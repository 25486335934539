<div class="row">
    <div class="col-md-3 col-sm-12">
            <h3>
                Evaluation Data     
            </h3>      
    </div>
    <div class="col-md-5">
        <input class="" fullWidth [(ngModel)]="searchbyfields"  nbTooltip="Search Training" nbTooltipStatus="primary"  type="text"  fieldSize="large" nbInput placeholder="Search Evaluation">   
    </div>
    <div class="col-md-4 col-sm-8 mt-1">
        <a nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" [routerLink]="['/hr/evaluation/grid']" nbButton status="primary"> <nb-icon icon="grid-outline"></nb-icon>   </a>
        &nbsp;
        <a nbTooltip="Visualize data on dimensions grid 3x3" nbTooltipStatus="primary" [routerLink]="['/hr/evaluation/keypad']" nbButton status="warning"> <nb-icon icon="keypad-outline"></nb-icon>   </a>
        &nbsp;
        <a [routerLink]="['/hr/evaluation/table']" nbTooltip="Visualize data in table" nbTooltipStatus="primary" nbButton status="basic"> <nb-icon icon="list-outline"></nb-icon>   </a>
        &nbsp;
        <button nbTooltip="Export to excel file" nbTooltipStatus="primary" nbButton status="success"><i class="fas fa-file-excel text-white"></i>  </button>
        &nbsp;

        <a [routerLink]="['/hr/evaluation/create']" nbTooltip="Create Evaluation" nbTooltipStatus="primary" nbButton status="info">
            <nb-icon icon="plus-outline"></nb-icon>
            Create new
        </a>
     
          <br>
    </div>
</div>
<div class="row" *ngIf="!evaluations || evaluations.length === 0">
    <nb-alert outline="danger" class="w-100 mt-3">No Data Found.</nb-alert>
</div>
<div [hidden]="evaluations.length === 0" class="row" *ngIf="(evaluations | filter : searchbyfields).length === 0">
    <nb-alert outline="danger" class="w-100 mt-3">The item searched is not found. Please make sure while typing your data</nb-alert>
</div>

<div class="row">
    <div class="col-md-3 mt-3">
        <div class="row">
            <div class="col-lg-9">
                <nb-form-field>
                    <nb-icon nbPrefix class="mt-1" icon="funnel-outline"></nb-icon> 
                    <nb-select selected="0" (selectedChange)="filterByEmployee($event)"  fullWidth>
                        <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Employee</nb-option>
                        <nb-option value="All" (click)="getEvaluations()"> &nbsp;&nbsp;&nbsp;All</nb-option>
                        <nb-option *ngFor="let employee of employees" value="{{employee.id}}"> 
                            &nbsp;&nbsp;&nbsp; <nb-user [name]="employee.employeeName" [picture]="employee.employeeProfileImage"></nb-user>
                        </nb-option>
                     
                    </nb-select>
                </nb-form-field>
           
            </div>
        </div>
       
        <div class="row mt-3">
            <div class="col-lg-9">
                <nb-form-field>
                    <nb-icon nbPrefix class="mt-1" icon="funnel-outline"></nb-icon>
                    <nb-select (selectedChange)="filterByType($event)" selected="0" fullWidth>
                        <nb-option  disabled value="0"> &nbsp;&nbsp;&nbsp;Evaluation Type</nb-option>
                        <nb-option value="All" (click)="getEvaluations()"> &nbsp;&nbsp;&nbsp;All</nb-option>
                        <nb-option  value="Evaluation#1"> &nbsp;&nbsp;&nbsp; Evaluation#1</nb-option>
                        <nb-option  value="Evaluation#2"> &nbsp;&nbsp;&nbsp; Evaluation#2</nb-option>
                        <nb-option  value="Evaluation#3"> &nbsp;&nbsp;&nbsp; Evaluation#3</nb-option>
                        <nb-option  value="Evaluation#4"> &nbsp;&nbsp;&nbsp; Evaluation#4</nb-option>

                   
                    </nb-select>
                </nb-form-field>
              
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-9">
                <nb-form-field>
                    <nb-icon nbPrefix class="mt-1" icon="flip-outline"></nb-icon>
                    <nb-select selected="0" fullWidth>
                        <nb-option disabled value="0"> &nbsp;&nbsp;&nbsp;Limit Date</nb-option>
                        <nb-option value="All" (click)="getEvaluations()"> &nbsp;&nbsp;&nbsp;All</nb-option>
                        <nb-option (click)="getEvaluationsAsc()" value="Ascending">&nbsp;&nbsp;&nbsp; Ascending </nb-option>
                        <nb-option (click)="getEvaluationsDesc()" value="Descending">&nbsp;&nbsp;&nbsp; Descending </nb-option>
                    </nb-select>
                </nb-form-field>
              
            </div>
        </div>
    </div>
    <div class="col-lg-9 col-md-9 col-sm-9">
        <div class="row mt-3">
                <nb-card class="col-lg-6" *ngFor="let evaluation  of evaluations  | filter : searchbyfields | paginate : {itemsPerPage: pageSize, currentPage: current,totalItems: evaluations.length} ; let i =index">
       
                    <nb-card-body>
                        <div class="row">
                            <div class="col-md-10">
                                <nb-user [name]="evaluation?.employee?.employeeName" [picture]="evaluation?.employee?.employeeProfileImage" size="medium"></nb-user>
                            
                            </div>
                            
                            <div class="col-md-2">
                                <button shape="round" nbButton status="basic" size="small" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <nb-icon icon="more-horizontal-outline"></nb-icon>
                                  </button>
                                  <mat-menu #menu="matMenu">
                                   
                                    <a style="text-decoration:none" nbTooltip="Click here to edit data" nbTooltipStatus="primary">
                                        <button mat-menu-item>
                                          <span ><nb-icon class="text-warning" icon="edit-outline"></nb-icon>&nbsp; Edit</span>
                                        </button>
                                      </a>
                                
                                      <a nbTooltip="Click here to archive data" nbTooltipStatus="primary">
                                        <button mat-menu-item>
                                          <span><nb-icon class="text-secondary" icon="archive-outline"></nb-icon>&nbsp; Archive</span>
                                        </button>
                                      </a>
                                 
                                     
                                  </mat-menu>
                            </div>
                          
                        </div>  
                        <div class="row">
                         
                            
                              <div class="col-md-12 mt-2">
                                    <p> <nb-icon icon="text-outline" class="text-primary"></nb-icon> {{evaluation.evaluationType}}</p>
                              </div>
                              <div class="col-md-12 mt-2">
                                <p> <nb-icon icon="calendar-outline" class="text-primary"></nb-icon> {{evaluation.limitDate | date : 'dd EEEE MMM YYYY'}}</p>
                              </div>
                              <div class="col-md-12 mt-2">
                                 <a nbButton size="small" shape="round" status="danger" href="{{evaluation.attachmentEvaluation}}" target="_blank">
                                    <nb-icon icon="printer-outline"></nb-icon> View
                                </a> 
                              </div>
                        </div>      
            
                    </nb-card-body>
                </nb-card>
                
        </div>
        <nb-card class="card col-md-4" align="center" style="height: 35px; border-radius: 25px;">
            <pagination-controls class="pt-1 text-center" (pageChange)="current = $event"></pagination-controls>
        </nb-card>
      
    </div>
</div>


<br>