<div class="row">
       <div class="col-md-4">
              <h3>Edit Department</h3>
       </div>
       <div  class="col-md-8">
              <a style="float: right;" nbTooltip="Back to list" nbTooltipStatus="primary" shape="round" status="basic" [routerLink]="['/hr/department/list']" nbButton>
                     <nb-icon icon="corner-up-right-outline"></nb-icon>
              </a>
       </div>
</div>
<form (ngSubmit)="onSubmit()" class="mt-3">
       <label>Reference</label>
       <div class="row">
              <div class="col-md-11">
                     <input id="departmentReference"
                     name="departmentReference" 
                     [(ngModel)]="department.departmentReference"
                     type="text" 
                     class="form-control" 
                     nbTooltip="This field is on mode readonly" nbTooltipStatus="primary"
                     nbInput
                     fullWidth
                     readonly
                     placeholder="Department Reference"/><br> 
              </div>
              <div class="col-md-1">
                     <nb-icon icon="info-outline" nbTooltip="Please enter valid reference" nbTooltipStatus="primary"></nb-icon>

              </div>
       </div>

           
       <label class="mt-2">Name</label>

       <div class="row">

              <div class="col-md-11">
                     <input 
                     id="departmentName"
                     name="departmentName" 
                     [(ngModel)]="department.departmentName"
                     type="text" 
                     class="form-control" 
                     nbInput
                     fullWidth
                     placeholder="Department name"/> <br>
              </div>
              <div class="col-md-1">
                     <nb-icon icon="info-outline" nbTooltip="Please enter valid reference" nbTooltipStatus="primary"></nb-icon>
   
              </div>
       </div>

       <div class="row">
              <div class="col-md-11">
                 <nb-select [selected]="employeeSelected"
                    [(ngModel)]="department.employee"
                    name="employee"
                    nbTooltip="Select Responsable" nbTooltipStatus="primary"
                    id="employee"
                    fullWidth
                    fieldSize="large"
                    placeholder="select Responsable"
                    (selectedChange)="getEmployeeSelected($event)">
            <nb-option *ngFor="let empS of listEmps" value="{{empS.id}}">{{empS.employeeName}}</nb-option>
    
        </nb-select>
        <nb-tag-list class="mt-2">
              <nb-tag status="basic" appearance="filled" text="Responsable Selected : {{department?.employee?.employeeName}}"></nb-tag>
       
            </nb-tag-list>
              </div>
              <div class="col-md-1">
                 <nb-icon icon="info-outline" nbTooltip="Please enter a valid name" nbTooltipStatus="primary"></nb-icon>

              </div>
       </div>

       <label class="mt-2">Level</label>

       <div class="row">
              <div class="col-md-11">
                 <nb-select [selected]="levelSelected"
                 [(ngModel)]="department.level"
                 name="level"
                 nbTooltip="Select Level" nbTooltipStatus="primary"
                 id="level"
                 fullWidth
                 fieldSize="large"
                 placeholder="select Level"
                 (selectedChange)="getLevelSelected($event)">
         <nb-option *ngFor="let lvl of listLvls" value="{{lvl.id}}">{{lvl.levelReference}}</nb-option>
 
     </nb-select>
     <nb-tag-list class="mt-2">
       <nb-tag status="basic" appearance="filled" text="Level Selected : {{department?.level?.levelReference}}"></nb-tag>

     </nb-tag-list>

    
              </div>
              
              <div class="col-md-1">
                 <nb-icon icon="info-outline" nbTooltip="Please enter a valid name" nbTooltipStatus="primary"></nb-icon>

              </div>
       </div>

           
   
           
    <div class="row mt-3">
           <div class="col-md-11">
              <button style="float: right;" nbTooltip="Once clicked, the changes are committed" nbTooltipStatus="primary" status="success"  nbButton type="submit"  class="btn btn-primary">
                     Save new changes
             </button>
           </div>

    </div>

</form>